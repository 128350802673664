export default [
  {
    title: "Big, Bold and Dynamic",
    text:
      "We offer contstant repetitive exposure, 24 hours a day, 7 days a week. <br/><br/>This ensures that we always reach a captive audience and with most campaigns remaining in situ for at least a month.<br/><br/> our locations guarantee that people will see your message and branding a number of times -a principal factor for message reinforcement.",
  },
  {
    title: "Endless outdoor solutions",
    text:
      "With an extensive portfolio that combines billboards, airports, mobile vans,  posters, traffic signage, gantrys, in prime locations and more.<br/><br/>We are regularly adding sites to our portfolio giving you a greater choice of locations and can design a strategy and plan a campaign to suit your target audiences",
  },
  {
    title: "Creative First",
    text:
      "Over 70% of an ad’s effectiveness is due to its creative. <br/><br/>That’s why Adcity Advertising puts creative first for our clients. We deliver best-in-class design for marketers and brands.",
  },
]
