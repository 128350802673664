import React from "react"
import Grid from "../Grid/Grid"
import Product from "../Products/Product"
import Button from "../Button/Button"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const getProducts = graphql`
  query {
    featuredProducts: allContentfulProducts(
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          name
          contentful_id
          slug
          description {
            description
          }
          images {
            fluid(quality: 90, maxWidth: 768) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
const TitleArea = styled.div`
  @media (min-width: 768px) {
    grid-column: 1 / 2;
  }
`

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 2.125rem;
`

const FlexContainer = styled.div`
  grid-column: 1/4;
  margin-left: -20px;
  margin-right: -20px;
  @media (min-width: 768px) {
    grid-column: 2 / 4;
    display: flex;
    justify-content: space-between;

    p {
      margin-top: 0;
    }
  }
`

const FlexItem = styled.div`
  flex: 0 0 50%;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`

const FeaturedProducts = ({ largePadding, id }) => {
  const response = useStaticQuery(getProducts)
  const products = response.featuredProducts.edges

  return (
    <section
      id={id}
      className={largePadding ? "section-padding--large" : "section-padding"}
    >
      <Grid>
        <TitleArea>
          <Title>What we offer</Title>
          <p>
            We provide striking, eye-catching advertising products that are
            capable of reaching large, targeted audiences. At Adcity Advertising
            we are effective at reaching audiences that other forms of media
            find difficult to reach, such as young audiences.
          </p>
          <Button text="All Products" link="/products/" />
        </TitleArea>
        <FlexContainer>
          {products.map(({ node }) => {
            return (
              <FlexItem key={node.contentful_id}>
                <Product product={node} />
              </FlexItem>
            )
          })}
        </FlexContainer>
      </Grid>
    </section>
  )
}

export default FeaturedProducts
