import React from "react"
import Hero from "../components/Hero/Hero"
import Banner from "../components/Banner/Banner"
import About from "../components/About/About"
import Service from "../components/Service/Service"
import StyledAbout from "../components/StyledAbout/StyledAbout"
import FeaturedProducts from "../components/FeaturedProducts/FeaturedProducts"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import { Link } from "react-scroll"
import styled from "styled-components"

const Index = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
      aboutSectionImg: file(relativePath: { eq: "cleartrip-van.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const FlexDiv = styled.div`
    background-color: #f0f8ff;
    background-position: center;
    background-size: cover;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  return (
    <>
      <SEO title="Home" />
      <Hero>
        <Banner
          title={data.site.siteMetadata.title}
          info={data.site.siteMetadata.description}
        >
          <Link className="btn" to="about" smooth={true} duration={500}>
            Learn More
          </Link>
        </Banner>
      </Hero>
      <StyledAbout
        gradient="true"
        img={data.aboutSectionImg.childImageSharp.fluid}
      >
        <About id="about" largePadding={true} />
      </StyledAbout>
      <Service largePadding={true} />
      <FlexDiv>
        <FeaturedProducts id="products" largePadding={true} />
      </FlexDiv>
    </>
  )
}

export default Index
