import React from "react"
import styled from "styled-components"
import Grid from "../Grid/Grid"
import { Link } from "react-scroll"

const TitleArea = styled.div`
  grid-column: 1 / 4;
  @media (min-width: 768px) {
    grid-column: 1 / 2;
  }
`

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 2.125rem;
`

const ContentArea = styled.div`
  grid-column: 1/4;
  @media (min-width: 768px) {
    grid-column: 2 / 3;

    p {
      margin-top: 0;
    }
  }
`

const About = ({ largePadding, id }) => {
  return (
    <section
      id={id}
      className={largePadding ? "section-padding--large" : "section-padding"}
    >
      <Grid>
        <TitleArea>
          <Title>We are the leader in outdoor advertising in Goa.</Title>
          <Link
            className="btn-white-text"
            to="products"
            smooth={true}
            duration={500}
          >
            View Products
          </Link>
        </TitleArea>
        <ContentArea>
          <p>
            We focus exclusively on outdoor advertising, developing in areas of
            business: transport, billboard, bus shelters, hoarding, mobile vans,
            traffic signage and gantry advertising.
          </p>
        </ContentArea>
      </Grid>
    </section>
  )
}

export default About
